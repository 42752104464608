<template>
  <div class="container pd100 schedule-calendar">
      <vue-hash-calendar 
        ref="calendar"
        @click="handleClick"
        @change="handleChange"
        @slidechange="handleSlidechange"
        checkedDayClassName="schedule-calendar-color"
        format="YY-MM-DD"
        :defaultDatetime="defaultDatetime"
        :visible="true" 
        :isShowArrow="false" 
        :isShowAction="false" 
        :scrollChangeDate="true"
        :isShowWeekView="isShowWeekView"
        weekStart='monday'
        :markDate="markDate">
        <!-- <div slot="day" slot-scope="scope">{{ scope.date.day }}</div> -->
        <!-- <div @click="isShowWeeks" slot="arrow">
          <img v-if="isShowWeekViews" src="@/assets/download.png">
          <img v-else src="@/assets/download-2.png">
        </div> -->
      </vue-hash-calendar>

      <van-button v-if="book_show" type="primary" style="width:90%;text-align:center;margin:0 auto;margin-top: 10px;" round :to="book_appointment" @click="clickAppointment" block>去约课</van-button>

      <div v-if="list.length > 0" class="schedule-list">
        <div @click="toDetail(course)" v-for="(course,index) in list" :key="index">

          <van-divider v-if="course.class_date!=active && showDateTxt(index, course.class_date)">
            {{dayjs(course.class_date).format('MM/DD')}}
            <!-- 周{{weeks_name[dayjs(course.class_date).day()]}} -->
          </van-divider>

          <div class="schedule-list-item">
            <div class="schedule-list-item-hd">
              <div class="schedule-list-item-dot" />
              <div class="schedule-list-item-time">
                <div class="schedule-list-item-time-title">{{ course.class_start }}</div>
                <div class="schedule-list-item-time-txt disabled">{{ dayjs(course.class_date).format('MM-DD') }}</div>
                <div class="schedule-list-item-time-txt disabled">{{ dayjs(course.class_date).format('dddd') }}</div>

                <!-- <div v-if="course.book_status === 1" class="schedule-list-item-time-txt">待上课</div> -->
                <div v-if="course.book_status === 2" class="schedule-list-item-time-txt disabled">已上课</div>
                <div v-if="course.book_status === 0" class="schedule-list-item-time-txt orange">已请假</div>
                <div v-if="course.book_status === 4" class="schedule-list-item-time-txt orange">等位中</div>
              </div>
            </div>
            <div class="schedule-list-item-bd">
              <div class="schedule-list-item-title van-ellipsis">
                {{ course.course_title }}
              </div>
              <div class="schedule-list-item-location">上课老师：{{ course.course_teacher && course.course_teacher.teacher_name }}·{{ course.room_name }}</div>
              <div class="schedule-list-item-location">上课学员：{{ course.student_name}} </div>
              <!-- <div class="schedule-list-item-location">上课教室：</div> -->
              <div class="schedule-list-item-location">上课校区：{{ course.course_studio && course.course_studio.studio_name }}</div>            
            </div>

            <div class="schedule-list-item-chat" v-if="course.book_status==1">
              <van-tag plain color="#ff993e">去请假</van-tag>
            </div>
            <div class="schedule-list-item-chat" v-if="course.book_status==2">
              {{course.cost_times}}
            </div>
          </div>

        </div>
      </div>
      
    <div v-else class="no-data">
      <img :src="no_order" class="no-data-image">
      <div class="no-data-title">暂无排课</div>
    </div>

    <copyright />
    <!-- 底部导航栏 -->
    <Tabbar/>
    <loadings :loading='searchLoading' />
  </div>
</template>

<script>
import { Calendar , Tag ,Divider } from 'vant'
import Schedule from '@/components/Schedule'
import Loadings from '@/components/loading'
import PageMixin from '@/mixins/page'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'
dayjs.locale('zh-cn')
import no_order from '@/assets/no_order.png'
import Copyright from '@/components/Copyright'
import Tabbar from '@/components/Tabbar'

export default {
  name: 'Timetable',
  components: {
    Copyright,
    [Divider.name]:Divider,
    [Tag.name]:Tag,
    Tabbar:Tabbar,
    [Calendar.name]: Calendar,
    schedule: Schedule,
    loadings:Loadings
  },
  mixins: [PageMixin],
  data() {
    return {
      book_show:false,
      book_appointment:'appointment',
      active: dayjs().format('YYYY-MM-DD'),
      divider_date:'',
      weeks: [],
      weeks_name:{1:'一','2':'二','3':'三','4':'四','5':'五',6:'六',0:'日'},
      no_order,
      defaultDatetime:new Date(dayjs().year(), dayjs().month(), dayjs().date()),
      activeBar:2,
      moveTop:415,
      moveLeft:317,
      searchLoading:false,
      isShowWeekView:true,
      isShowWeekViews:true,
      list: [],
      markDate:[],
      start_date:dayjs(dayjs().startOf('month')).add(-1, 'month').format('YYYY-MM-DD'),
      end_date:dayjs(dayjs().endOf('month')).add(1, 'month').format('YYYY-MM-DD'),
    }
  },
  computed: {
  },
  created() {
    this.isShowWeekView = localStorage.getItem('isShowWeekViews')=='true'?true:false
    this.isShowWeekViews = localStorage.getItem('isShowWeekViews')=='true'?true:false
    if(dayjs().hour()>=22){
        this.defaultDatetime = new Date(dayjs().year(), dayjs().month(), dayjs().date()+1)
        this.times = dayjs().add('1','day').format('YYYY-MM-DD')
    }else{
        this.times = dayjs().format('YYYY-MM-DD')
    }
    this.getList()
    let appid=localStorage.getItem('appid');
    if(appid=='mMKcL4'){
      this.book_show=true
      this.book_appointment="huidong/appointment";
    }else{
      if(appid=='c8bEt1' || appid=='RGid7' || appid=='8xn0L4'){
        this.book_show=true
        this.book_appointment="appointment";
      }
    }
  },
  activated() {
    this.getList()
  },
  beforeRouteLeave(to, from, next) {
      if (to.path === '/student/schedule/detail') { // 去往详情页的时候需要缓存组件，其他情况下不需要缓存
          this.$store.commit('app/updateCatchComponents', ['Timetable'])
      } else {
          this.$store.commit('app/updateCatchComponents', [])
      }
      next()
  },
  methods: {
    dayjs,
    showDateTxt(index,date){
      if(!this.list[index-1]){
        if(date!=this.active){
          return true;
        }else{
          return false;
        }
      }
      if(this.list[index-1].class_date == date){
        return false;
      }else{
        return true;
      }
      
    },
    handleTouchstart(e){
        this.moveTop = e.changedTouches[0].clientY - 20
        this.moveLeft = e.changedTouches[0].clientX - 20
    },
    handleTouchmove(e){
        e.preventDefault()
        this.moveTop = e.changedTouches[0].clientY - 20
        this.moveLeft = e.changedTouches[0].clientX - 20
    },
    clickAppointment(){
      this.$api.click_index({event_id:0,studio_id:0,event_type:'appointment_btn'}).then(res=>{})
    },
    handleTouchend(e){
        this.moveTop = e.changedTouches[0].clientY - 20
        this.moveLeft = e.changedTouches[0].clientX - 20
    },
    getList(){
      this.searchLoading = true
      this.$api.schedule_index({ start_date:this.start_date, end_date:this.end_date}).then(res => {
        let markDate = []
        this.weeks = res.data.booking
        this.list =[]
        res.data.booking.forEach(item=>{
          markDate.push(dayjs(item.date).format('YYYY/MM/DD'))
          if(item.date >= this.active){
            this.list = this.list.concat(item.courses)
            // this.list = item.courses
          }
        })

        this.markDate = [{color: '#ecbe4b',date: markDate}]
        this.searchLoading = false
      })
    },
    handleClick(e){
      this.list = [];
      for(let i=0; i<this.weeks.length;i++){
        if(this.weeks[i].date >= e){
          this.list = this.list.concat(this.weeks[i].courses) 
        }else{
          this.list = []
        }
      }
    },
    handleChange(e){
      this.active = e
      this.start_date = dayjs(dayjs(e).startOf('month')).add(-1, 'month').format('YYYY-MM-DD')
      this.end_date = dayjs(dayjs(e).endOf('month')).add(1, 'month').format('YYYY-MM-DD')
    },
    handleSlidechange(e){
      if (e=='up' || e=='down') return false
      let that = this
      setTimeout(()=>{
        that.getList()
      },0)
    },
    toDetail(item){
      this.$router.push({path:'/student/schedule/detail',query:{book_id:item.book_id}})
    },
    isShowWeeks(){
      this.isShowWeekViews = !this.isShowWeekViews
      localStorage.setItem('isShowWeekViews',this.isShowWeekViews)
    }
  },
}
</script>
<style lang="less" scoped>
  .schedule-list{
    // min-height: 220px;
    padding: 10px 15px;
  }

  @height:80px;
  .schedule-list-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    background: #fff;
    padding-left: 10px;
    position: relative;
    &-hd{
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 10px;
      height: @height;
      padding-left: 20px;
      padding-top: 8px;
    }
    &-dot{
      position: absolute;
      top: 14px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      overflow: hidden;
      background: #00DE93;
      margin-right: 20px;
    }
    &-time{
      font-weight: bold;
      font-size: 16px;
      &-title{
        // margin-bottom: 5px;
      }
      &-txt{
        font-size: 12px;
        font-weight: normal;
      }
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
      font-weight: bold;
    }
    &-teacher{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 10px;
      color: #666;
      margin-bottom: 5px;
      &-img{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 5px;
      }
    }
    &-bd{
      box-sizing: border-box;
      // height: @height;
      padding: 10px 15px;
      // background: #f6f9fc;
      border-radius: 4px;
      flex: 1;
      font-size: 10px;
      color: #666;
      min-width: 0;
    }
    .gray{
      color: #b2b2b2;
    }
    .orange{
      color: #ff993e;
    }
    &-chat{
      position: absolute;
      top: 30px;
      right: 10px;
    }
  }

  .no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 15px;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }
  }
</style>
<style lang="less">  
  .schedule-calendar{
    .calendar_content{
      overflow: initial;
      // font-family: Helvetica-Neue,Helvetica,Arial,sans-serif !important
      // height: 80px !important;
    }
    .calendar_day{
      font-size:3.33333333333334vw;
    }
    .schedule-calendar-color{
        background:#00cca2;
        color:#fff !important;
    }
    .calendar_first_today{
      color:#00cca2;
    }
  }
  .float-ball{
        position: fixed;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        .van-icon{
            font-size: 0.8rem;
        }
  }
</style>

